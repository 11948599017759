import React from 'react';
import ContactHome from './components/contact-home';
import MotionCard from './components/motion-card';
import CustomMargin from '../../components/custom-margin';
import MissionSection from './components/mission-section';
import FAQSection from './components/faq-section';
import { LOCATION_ADD } from '../../components/constants';

export default function Home(){
    return(
        <div>
            <ContactHome/>
            <CustomMargin className='p-5'>
                <h1 className='font-fontMontserrat text-5xl'>Law in Motion</h1>
                <MotionCard/>
                <div className='mt-14'>
                    <MissionSection/>
                </div>
            </CustomMargin>
            <div className='bg-black mt-10 mb-5 p-5 pb-10 flex gap-5'>
               <CustomMargin className='flex gap-5 lg:flex-col sm:flex-col'>
                    <div className='w-1/2 sm:w-full lg:w-full'>
                        <h1 className='font-fontMontserrat text-5xl text-white'>FAQs</h1>
                        <FAQSection/>
                    </div>
                    <div className='w-1/2 sm:w-full lg:w-full lg:mt-10'>
                        <h1 className='font-fontMontserrat text-5xl text-white'>OUR OFFICE</h1>
                        <h1 className='text-xl text-white'>{LOCATION_ADD}</h1>
                        <iframe title='Google Map QLO' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d387.45156976927985!2d123.8985644!3d10.3306627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a9998fef8428b1%3A0x931c003a8a5118d4!2sQUILATON%20LAW%20OFFICE!5e0!3m2!1sen!2sph!4v1696929498263!5m2!1sen!2sph" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
               </CustomMargin>
            </div>
        </div>
    );
}