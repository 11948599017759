import CustomMargin from '../../../components/custom-margin'
import { MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { EMAIL_ADD, PHONE_NUMBER, LOCATION_ADD } from '../../../components/constants';


export default function ContactHome(){
    return (
        <div className='bg-black mt-10 mb-10 p-10'>
            <CustomMargin className='text-white flex sm:flex-col lg:flex-row lg:items-baseline justify-center items-center gap-10 h-auto'>
                <div className='flex flex-col items-center w-1/3 
                                sm:w-full sm:border-b sm:pb-5
                                lg:border-r lg:border-b-0 lg:pr-5 lg:pb-0' >
                    <div className='flex items-center gap-3'>
                        <MailIcon className='h-10 w-10'/>
                        <h1 className='text-3xl font-fontMontserrat'>EMAIL</h1>
                    </div>
                    <h1 className='text-xl mt-5 text-center'>{EMAIL_ADD}</h1>
                </div>
                <div className='flex flex-col items-center w-1/4 sm:w-full'>
                    <div className='flex items-center gap-3'>
                        <LocationMarkerIcon className='h-10 w-10'/>
                        <h1 className='text-3xl font-fontMontserrat'>VISIT US</h1>
                    </div>
                    <h1 className='text-xl mt-5 text-center'>{LOCATION_ADD}</h1>
                </div>
                <div className='flex flex-col items-center w-1/3 
                                sm:w-full sm:border-t sm:pt-5
                                lg:border-l lg:border-t-0 lg:pl-5 lg:pt-0'>
                    <div className='flex items-center gap-3'>
                        <PhoneIcon className='h-10 w-10'/>
                        <h1 className='text-3xl font-fontMontserrat'>PHONE</h1>
                    </div>
                    <h1 className='text-xl mt-5'>{PHONE_NUMBER}</h1>
                </div>
            </CustomMargin>
        </div>
    )
}