import CustomMargin from "../../../components/custom-margin";
import EyeIcon from '../../../assets/eye-vision.svg';
import TargetIcon from '../../../assets/target-mission.svg';
import HandIcon from '../../../assets/hand-values.svg';

export default function MissionAbout(){
    return(
        <CustomMargin className="pb-5 pt-5 w-3/4">
            <div>
                <div className='flex gap-5 items-center justify-center'>
                    <img 
                        alt="Target Icon"
                        src={TargetIcon}
                        className="filter invert h-44 sm:hidden lg:flex"
                    />
                    <div className="sm:text-center xxl:text-left">
                        <h2 className="font-fontMontserrat text-7xl sm:text-5xl">MISSION</h2>
                        <p className="w-full">Quilaton Law Office aims to provide affordable and seamless legal services to our clients by effectively combining our highly diverse and specialized legal skills and passion to serve the people — locally and globally.</p>
                    </div>
                </div>
                <div className='flex items-center justify-center mt-10 gap-5'>
                    <div className="w-full sm:text-center xxl:text-right">
                        <h2 className="font-fontMontserrat text-7xl sm:text-5xl">VISION</h2>
                        <p>The Quilaton Law Office envisions a future where our law firm is among the superior law firms in the country capable of providing timely but top-notch services to our growing clients.</p>
                        <p>Our law firm will continuously be the avenue for clients seeking peace of mind in their legal concerns at an affordable rate.</p>
                    </div>
                    <img 
                        alt="Eye Icon"
                        src={EyeIcon}
                        className="filter invert h-56 sm:hidden lg:flex"
                    />
                </div>
                <div className='flex mt-10 gap-5 items-center'>
                    <img 
                        alt="Handshake Icon"
                        src={HandIcon}
                        className="filter invert h-56 sm:hidden lg:flex"
                    />
                    <div className="sm:text-center xxl:text-left">
                        <h2 className="font-fontMontserrat text-7xl sm:text-5xl">CORE VALUES</h2>
                        <p className="w-full">Our core values include collaboration, compassion and empathy, efficiency, integrity, innovation and sustainability, and justice.</p>
                    </div>
                </div>
            </div>
        </CustomMargin>
    )
}