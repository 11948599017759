import React, { useState } from 'react';
import CustomMargin from "../../../components/custom-margin";

const faq = [
    {
        question: 'How much are your legal services?',
        answer:"The Quilaton Law Office follows the standard minimum attorney's fee schedule regulated by the Integrated Bar of the Philippines (IBP) Cebu City Chapter, which is the official organization of lawyers recognized by the Supreme Court. For more details, kindly contact us and we will respond to you as soon as possible.",
    },
    {
        question: "What are the payment options you accept?",
        answer: "We cater the following modes of payment: Cash, Eastwest Bank Transfer, BPI Bank Transfer, GCash, or via Remittance Centers."
    },
    {
        question: "Do I really need an attorney?",
        answer: "When in doubt about your legal concerns, it is highly recommended to reach out to a lawyer as soon as possible to avoid your circumstances from escalating. Either you are looking for a retainer lawyer for your business or other personal matters, you can rely on Quilaton Law Office."
    }
];

export default function FAQSection() {
    const [visibleAnswers, setVisibleAnswers] = useState(Array(faq.length).fill(false));

    const toggleAnswerVisibility = (index) => {
        const newVisibleAnswers = [...visibleAnswers];
        newVisibleAnswers[index] = !newVisibleAnswers[index];
        setVisibleAnswers(newVisibleAnswers);
    };

    return (
        <CustomMargin>
        {faq.map((ask, index) => (
            <div key={ask.question} className="text-white border border-white rounded-md mt-5">
                <h1 
                    className="p-5 bg-white text-black cursor-pointer hover:text-white hover:bg-black duration-300 ease-in-out" 
                    onClick={() => toggleAnswerVisibility(index)}
                >
                    {ask.question}
                </h1>
                <div className={`overflow-hidden transition-all duration-500 ease-in-out ${visibleAnswers[index] ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
                    <p className="p-5">
                        {ask.answer}
                    </p>
                </div>
            </div>
        ))}
        </CustomMargin>
    );
}
