import { useState } from 'react';
import CardPost from "./component/card-post";
import PracticeArea from "./component/practice-area";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

export default function Practices(){
    const [latestDate, setLatestDate] = useState(null);

    const handleLatestDateUpdate = (date) => {
        setLatestDate(formatDate(date));
    };

    return(
        <div>
            <PracticeArea/>
            <div className="flex flex-col items-center mt-20">
                <div className="flex justify-between items-end w-full max-w-7xl sm:p-5">
                    <h2 className="font-fontMontserrat text-4xl">Recent Posts</h2>
                    <p className="text-slate-400">Last updated {latestDate}</p>
                </div>
                <CardPost onLatestUpdate={handleLatestDateUpdate}/>
            </div>
        </div>
    )
}