// Helper function to chunk the posts array into smaller arrays of specified size
export const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

export const truncateText = (details, limit) => {
    const words = details.split(" ");

    if(words.length > limit){
        return {
            truncated: words.slice(0, limit).join(' ') + '...',
            isTruncated: true || undefined,
        };
    }

    return {
        truncated: details,
        isTruncated: false || undefined,
    };
}

export const formatTitleForURL = (title) => {
    return encodeURIComponent(title)
        .toLowerCase()
        .replace(/%20/g, '-') // Replace spaces with hyphens
        .replace(/[!'()*]/g, escape); // Escape special characters
};


// Utility function to reverse the format
export const reverseFormatTitle = (formattedTitle) => {
    return decodeURIComponent(formattedTitle
        .replace(/-/g, ' ') // Replace hyphens with spaces
        .replace(/%20/g, ' ') // Replace encoded spaces back to spaces
    );
};
