import { useEffect, useState } from 'react';
import QLOLogo from '../assets/QLOLogoMark.png';
import NavLinks from "./nav-links";
import CustomMargin from './custom-margin';
import { Link } from 'react-router-dom';

export default function ScrolledNav() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`bg-black p-2 ${isScrolled ? 'block sticky top-0 z-50 h-auto' : 'h-0 hidden'} transition-all duration-500 ease-in-out`}>
           <CustomMargin>
            <div className="flex items-center justify-between">
                <Link
                        to='/' 
                    >
                        <img 
                            src={QLOLogo} 
                            alt="QLO Logo"
                            className="w-96 sm:w-60 md:w-72"/>
                    </Link>
                <div>
                    <NavLinks/>
                </div>
            </div>
           </CustomMargin>
        </div>
    );
}
