import CustomMargin from '../../components/custom-margin';
import AttyProfile from './components/atty-profile';
import MissionAbout from './components/mission-about';
import GlobalContact from '../../components/global-contact';

export default function AboutUs(){
    
    return(
        <div className='overflow-hidden'>
            <AttyProfile/>
            <div className='bg-buildingBg flex flex-col bg-cover shadow-inner-custom justify-center items-center text-white p-5 mb-10'>
                <MissionAbout/>
            </div>
            <CustomMargin>
                <div className='flex flex-col justify-center items-center h-auto mb-10'>
                    <div className='text-center font-fontMontserrat 
                                    sm:text-2xl sm:mb-5
                                    lg:text-4xl
                                    xxl:text-5xl'>
                        <p>JY Square Mall Office</p>
                        <p>Directional Video</p>
                    </div>
                    <iframe className='w-[550px] h-[1100px] 
                                    sm:w-[150px] sm:h-[300px]
                                    lg:w-[350px] lg:h-[700px]
                                    xxl:w-[550px] xxl:h-[1100px]' 
                            title='Facebook Video' 
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D1698434457605329&show_text=0&autoplay=1"> 
                    </iframe>
                </div>
            </CustomMargin>
        </div>
    );
}