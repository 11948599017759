import CustomMargin from "../../../components/custom-margin";
import BriefcaseIcon from '../../../assets/briefcase.svg';
import HandshakeIcon from '../../../assets/handshake.svg';
import FamilyIcon from '../../../assets/family.svg';
import ManIcon from '../../../assets/man-with-a-tie.svg';
import BuildingIcon from '../../../assets/building.svg';
import HandcuffIcon from '../../../assets/handcuffs.svg';


export default function PracticeArea(){
    return(
        <CustomMargin className="flex flex-col justify-center items-center">
                <h2 className="text-center mt-5 mb-10 text-2xl text-bold max-w-5xl sm:text-xl">We cater to the needs of local and international clients, offering a diverse range of services including, but not limited to:</h2>
                <div className="flex flex-col justify-center items-center space-y-10">
                    <div className="flex justify-center items-center sm:gap-5 sm:p-2 sm:flex-col md:flex-row">
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={BriefcaseIcon} 
                                    alt="Briefcase Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"
                                />
                                <div className="text-center">
                                    Corporate and Business Law
                                </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={HandshakeIcon} 
                                    alt="Handshake Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"/>
                                <div className="text-center">
                                    Dispute Resolution and Litigation
                                </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={FamilyIcon} 
                                    alt="Family Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"/>
                                <div className="text-center">
                                    Family and Personal Law
                                </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center sm:gap-5 sm:p-2 sm:flex-col md:flex-row">
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={ManIcon} 
                                    alt="Man Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"/>
                                <div className="text-center">
                                    Labor and Employment Law
                                </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={BuildingIcon} 
                                    alt="Building Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"/>
                                <div className="text-center">
                                    Property and Construction Law
                                </div>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                                <img 
                                    src={HandcuffIcon} 
                                    alt="Handcuff Icon"
                                    className="w-48 h-48 border-8 p-5 border-black rounded-full mb-5 hover:h-52 hover:w-52 transition-all duration-300 ease-in-out
                                                sm:w-36 sm:h-36 sm:hover:w-44 sm:hover:h-44
                                                lg:w-48 lg:h-48"/>
                                <div className="text-center">
                                    Criminal and Regulatory Law
                                </div>
                        </div>
                    </div>
                </div>
            </CustomMargin>
    );
}